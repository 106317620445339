<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Create Business Entity
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-card>
      <b-media class="mb-2">
        <template #aside>
          <b-avatar
            ref="previewEl"
            :src="logoSrc"
            size="60px"
            rounded
          />
        </template>
        <div class="d-flex flex-wrap">
          <b-button
            variant="primary"
            @click="$refs.refInputEl.$el.childNodes[0].click()"
          >
            <b-form-file
              ref="refInputEl"
              v-model="logo"
              type="file"
              class="d-none"
              accept="image/*"
              @input="setImage"
            />
            <span class="d-none d-sm-inline">Choose</span>
            <feather-icon
              icon="EditIcon"
              class="d-inline d-sm-none"
            />
          </b-button>
          <b-button
            variant="outline-secondary"
            class="ml-1"
            @click="signature = null; logoSrc = null"
          >
            <span class="d-none d-sm-inline">Remove</span>
            <feather-icon
              icon="TrashIcon"
              class="d-inline d-sm-none"
            />
          </b-button>
        </div>
      </b-media>
      <validation-observer
        ref="businessEntityCreateForm"
        #default="{invalid}"
      >
        <b-form @submit.prevent="submitForm">
          <b-row>
            <b-col cols="md-6">
              <b-form-group
                label="Business Entity Name*"
                label-for="h-business-entity-name"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Business Entity Name"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    id="h-business-entity-name"
                    v-model="name"
                    placeholder="Business Entity Name"
                    :state="(errors.length > 0 || nameValidation) ? false : null"
                    name="name"
                    @input="nameValidation == true ? nameValidation = false : null"
                    @blur="generateInitial"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="nameValidation"
                    class="text-danger"
                  >
                    {{ nameError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-6">
              <b-form-group
                label="Initial for PO*"
                label-for="h-business-entity-initialForPO"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Initial for PO"
                  vid="initialForPO"
                  rules="required"
                >
                  <b-form-input
                    id="h-business-entity-initialForPO"
                    v-model="initialForPO"
                    placeholder="MPL"
                    :state="(errors.length > 0 || initialForPOValidation) ? false : null"
                    name="initialForPO"
                    @input="initialForPOValidation == true ? initialForPOValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="initialForPOValidation"
                    class="text-danger"
                  >
                    {{ initialForPOError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Status"
                vid="status"
                rules="required"
              >
                <b-form-group
                  label="Status*"
                  label-for="h-business-entity-status"
                  label-cols-md="4"
                  :state="(errors.length > 0 || statusValidation) ? false : null"
                >
                  <v-select
                    id="h-business-entity-status"
                    v-model="status"
                    label="title"
                    :options="option"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="statusValidation == true ? statusValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="statusValidation"
                    class="text-danger"
                  >
                    {{ statusError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-6" />
          </b-row>

          <b-nav class="wrap-border save-nav">
            <li
              class="nav-item ml-auto"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                :to="{ name: 'directory-business-entities-index' }"
              >
                Cancel
              </b-button>
            </li>
            <li
              class="nav-item mr-1"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="success"
                :disabled="invalid"
              >
                <feather-icon
                  icon="SaveIcon"
                  class="mr-50"
                />
                <span class="align-middle">Save Business Entity</span>
              </b-button>
            </li>
          </b-nav>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BNav, BFormFile, BMedia, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BNav,
    BFormFile,
    BMedia,
    BAvatar,

    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      name: '',
      initialForPO: '',
      status: 'active',
      logo: null,
      logoSrc: '',
      nameError: 'Valid name is required',
      nameValidation: false,
      initialForPOError: 'Valid initail is required',
      initialForPOValidation: false,
      statusError: 'Valid status is required',
      statusValidation: false,
      option: [{ title: 'Active', code: 'active' }, { title: 'Inactive', code: 'inactive' }],

      // validation rules
      required,
    }
  },
  methods: {
    generateInitial() {
      this.initialForPO = this.name.split(' ').map(item => item.charAt(0)).join('').toUpperCase()
    },
    setImage() {
      if (this.logo && this.logo.size <= 1000000) {
        const reader = new FileReader()
        reader.readAsDataURL(this.logo)
        reader.onload = () => {
          this.logoSrc = reader.result
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please select a smaller image',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    submitForm() {
      this.$refs.businessEntityCreateForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('name', this.name)
          formData.append('initialForPO', this.initialForPO)
          formData.append('status', this.status)
          formData.append('logo', this.logoSrc)

          this.$http.post('directory/business-entities/store', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message ?? '',
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
              this.$router.push({ name: 'directory-business-entities-index' })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'name') {
                    this.nameError = validationError.msg
                    this.nameValidation = true
                  } else if (validationError.param === 'initialForPO') {
                    this.initialForPOError = validationError.msg
                    this.initialForPOValidation = true
                  } else if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>
